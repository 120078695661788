<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Add File</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleFileAdd" v-slot="{ errors }" ref="launchpad-send-file-form">
                        <h2>Add A File</h2>
                        <file-component is-list :errors="errors" :contacts="contacts.join(',')" :is-bulk-action="true" ref="file-component" />
                        <div class="action_wpr mt-5">
                            <button :disabled="fileLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="fileLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="fileLoader"></i>{{ fileLoader ? ' Adding' : 'Add' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapMutations } from 'vuex'

    const FileComponent = defineAsyncComponent(() => import('@/components/FileComponent'))

    export default {
        name: 'Add File',

        props: {
            modelValue: Boolean,
            contacts: Array,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            FileComponent,
        },

        watch: {
            modelValue (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.resetContactFiles([]);
            },
        },

        computed: mapState({
            fileLoader: state => state.fileModule.fileLoader,
        }),

        methods: {
            ...mapMutations({
                resetContactFiles: 'fileModule/SET_CONTACT_FILES',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleFileAdd () {
                const vm = this;
                const fileComponent = vm.$refs['file-component'];

                fileComponent.handleFileAdd();
            },
        },
    }
</script>
